.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
}

.flex-container {
  min-width:100px;
  max-width:800px;
  text-align:center;
  margin:0 auto;
}
.footer-content {
  text-align:center;
  padding: 2rem;
  padding-bottom: 120px

}
.site-footer {
  padding: 2rem;
  text-align: center;
}

.about-image{
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
  margin-left: -40px;
}

.image-holder {
  width: 350px;
  height: 350px;
  position: relative;
  overflow: hidden;
  border-radius: 90%;
  border: 5px solid whitesmoke;
}

/* .cv-button {
  margin-top: 100px;
} */

.tab-parent {
  display: grid;
  place-items: center;
  padding-top: 8rem;
}

.button-group {
  display: grid;
  place-items: center;
}